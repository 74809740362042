import { z } from 'zod';
import { claimAddress } from './address.js';
import { storeOrderLineItem } from './lineItem.js';
import { registrationProductVariant } from './storeProduct.js';

export const registration = z.object({
  id: z.number(),
  externalId: z.string(),
  createdOn: z.string(),
  estimatedPurchaseDate: z.string().nullish(),
  registrationChannelName: z.string(),
  proofOfPurchaseFileUrls: z.array(z.string()),
  orderIdFromPlatform: z.string(),
  email: z.string(),
  orderNumber: z.string().optional(),
  customerName: z.string(),
  address: claimAddress.optional(),
  lineItems: z.array(storeOrderLineItem),
});

export const registrationList = registration
  .pick({
    registrationChannelName: true,
    createdOn: true,
    externalId: true,
    email: true,
    customerName: true,
    id: true,
  })
  .array();

export const registrationCreate = registration
  .omit({
    customerName: true,
    externalId: true,
    orderIdFromPlatform: true,
    createdOn: true,
    registrationChannelName: true,
    proofOfPurchaseFileUrls: true,
    estimatedPurchaseDate: true,
    id: true,
    lineItems: true,
  })
  .merge(
    z.object({
      firstName: z.string(),
      lastName: z.string(),
      storeProductVariants: z.array(
        registrationProductVariant
          .pick({
            id: true,
          })
          .merge(z.object({ quantity: z.number() })),
      ),
      storeSalesChannelId: z.number(),
      estimatedPurchaseDate: z.string(),
      proofOfPurchaseFilenames: z.array(z.string()).optional(),
    }),
  );
