import { z } from 'zod';
import {
  CrewClaimRollupStatusCode,
  CrewClaimTypeEnum,
} from '../../enums/crew.js';
import { enumToZodLiteralUnion } from '../../zodExtensions.js';
import { stripeConfig } from '../customer/schema/settings.js';
import { claimAddress, claimAddressCreate } from './address.js';
import { claimLineItem, claimLineItemCreate } from './claimLineItem.js';
import { claimTimeline } from './claimTimeline.js';
import { storeOrderLineItem } from './lineItem.js';
import { shipment } from './shipment.js';

export const tag = z.object({
  name: z.string(),
});

const orderLineItemMetadataMinium = storeOrderLineItem.pick({
  id: true,
  imgUrl: true,
  name: true,
  quantity: true,
  unitPrice: true,
  unitTax: true,
  sku: true,
  optionsFromPlatform: true,
});

const resolutionSummary = z.object({
  refund: z
    //! crewClaimRefund table
    .object({
      id: z.number(),
      amount: z.number(),
      shippingFee: z.number(),
      handlingFee: z.number(),
      createdOn: z.string(),
      isRefundPending: z.boolean(),
    })
    .optional(),
  //! crewClaimGiftCard table
  giftCard: z
    .object({
      id: z.number(),
      amount: z.number(),
      incentiveAmount: z.number(),
      createdOn: z.string(),
      platformAdminUrl: z.string().optional(),
      orderNameFromPlatform: z.string().optional(),
    })
    .optional(),
  //! crewClaimExchangeOrder table
  exchangeOrder: z
    .object({
      id: z.number(),
      idFromPlatform: z.string(),
      orderNameFromPlatform: z.string(),
      createdOn: z.string(),
      lineItems: z.array(orderLineItemMetadataMinium),
      platformAdminUrl: z.string(),
    })
    .optional(),
});

export const shippingClaimResolutionSummary = resolutionSummary.pick({
  refund: true,
  exchangeOrder: true,
});

export const claimCustomerPayment = z.object({
  id: z.number(),
  idFromPlatform: z.string(),
  amount: z.number(), // ! non refundable amount (IE, fees, shipping, etc.)
  note: z.string().optional(),
  currencyCode: z.string(),
  status: z.union([
    z.literal('Authorized'),
    z.literal('Captured'),
    z.literal('Refunded'),
    z.literal('Expired'),
  ]),
  authorizationExpiresOn: z.string().nullable(),
  capturedOn: z.string().nullable(),
});

// * claim payment (collected through stripe)
export const claimCustomerPaymentCreate = z
  .object({
    idFromPlatform: z.string(),
    amount: z.number(), // ! non refundable amount (IE, fees, shipping, etc.)
    authorizedAmount: z.number().optional(), // ! refundable amount (IE, anything that is not a fee, the value of the instant exchange)
    currencyCode: z.string(),
  })
  .merge(z.object({ stripeConfig }));

//* claim
export const claim = z.object({
  id: z.number(),
  resolutionSummary: resolutionSummary.optional(),
  externalId: z.string(),
  linkToCustomerUi: z.string().optional(),
  returnIdFromPlatform: z.string().nullable(),
  // todo: consolidate with order.ts storeOrderClaim
  originalStoreOrder: z.object({
    id: z.number(),
    idFromPlatform: z.string(),
    orderNo: z.string().optional(),
    currencyCode: z.string(),
    createdOn: z.string(),
    platformAdminUrl: z.string().optional(),
    customerCurrencyConversionRate: z.number().optional(),
    wasCreatedFromRegistration: z.boolean(),
    registration: z
      .object({
        id: z.number(),
        externalId: z.string(),
      })
      .optional(),
  }),

  isInstantExchange: z.boolean(),
  noteToCustomer: z.string().optional(),
  customerEmail: z.string(),
  customerName: z.string().optional(),
  shippingAddress: claimAddress,
  billingAddress: claimAddress,

  claimRollup: z.object({
    name: z.string(),
    code: enumToZodLiteralUnion(CrewClaimRollupStatusCode),
  }),

  timeline: claimTimeline.optional(),

  // * gift card  incentive offered
  giftCardIncentiveAmountApplied: z.number(),

  //* optional handling fee for refunds
  feeApplied: z.number(),
  feeName: z.string().optional(),
  returnShippingAmountApplied: z.number(),

  // * conditional shipping amount charged based on store_setting of orderValueThresholdForFreeShipping applies to NEW ORDER (variant_exchange)
  exchangeOrderShippingAmountApplied: z.number(), // ! this can be deducted from a refund // or collected with stripe
  // newPayment: claimCustomerPayment2.optional(),
  customerPayment: claimCustomerPayment.optional(),

  // ? rename to claimTypeEnum to be consistent with other enum names
  claimType: enumToZodLiteralUnion(CrewClaimTypeEnum),
  claimLineItems: z.array(claimLineItem),

  //* claim return shipments are associated with a line_item, adding them here elevates the concept that a claim can have multiple shipments spread across N number of line items
  //* for all intents and purposes the length of this array should be 1, until we build out the ability to split out line items into separate return shipments
  claimReturnShipments: z.array(shipment).optional(),

  createdOn: z.string(),
  updatedOn: z.string().optional(),
  finalizedOn: z.string().optional(),
});

export const claimListItem = claim
  .pick({
    id: true,
    externalId: true,
    claimRollup: true,
    createdOn: true,
    customerEmail: true,
    customerName: true,
    claimType: true,
  })
  .merge(
    z.object({
      lineItemHasError: z.boolean(),
    }),
  );

export const claimList = z.array(claimListItem);
export const claimListCount = z.object({ count: z.number() });

export const claimCreate = claim
  .omit({
    id: true,
    isInstantExchange: true,
    externalId: true,
    updatedOn: true,
    createdOn: true,
    claimLineItems: true,
    claimRollup: true,
    claimReturnShipments: true,
    originalStoreOrder: true,
    noteToCustomer: true,
    timeline: true,
    customerPayment: true,
    returnIdFromPlatform: true,
    shippingAddress: true,
    billingAddress: true,
  })
  .merge(
    z.object({
      source: z.union([z.literal('Customer_App'), z.literal('Merchant_App')]),
      originalStoreOrderId: z.number(),
      claimLineItems: z.array(claimLineItemCreate).min(1),
      customerPayment: claimCustomerPaymentCreate.optional(),
      shippingAddress: claimAddressCreate,
      billingAddress: claimAddressCreate,
    }),
  );

export const claimUpdateCustomerInfo = claim.pick({
  id: true,
  shippingAddress: true,
  customerEmail: true,
});

export const claimUpdateFees = claim.pick({
  feeApplied: true,
});
